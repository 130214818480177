<template>
    <b-row>
        <b-col cols="12">
            <div
                class="d-sm-flex justify-content-between align-items-center mb-4"
            >
                <feather
                    type="arrow-left-circle"
                    v-b-popover.hover.top="'Presione esc'"
                    role="button"
                    class="text-secondary"
                    size="2rem"
                    @click="back"
                ></feather>
                <div class="d-flex flex-row">
                    <b-button
                        variant="secondary"
                        :disabled="isLoading"
                        v-b-popover.hover.top="'Presione esc'"
                        size="md"
                        class="mr-4"
                        @click="back"
                    >
                        Cancelar
                    </b-button>

                    <b-button
                        variant="primary"
                        :disabled="isLoading || read_only"
                        size="md"
                        class="d-flex align-items-center"
                        v-b-popover.hover.top="
                            'Presione ctrl+shift+s o cmd+shift+s'
                        "
                        @click="save"
                    >
                        <feather
                            type="save"
                            size="1rem"
                            class="mr-2 text-blue-active"
                        />
                        <span
                            v-if="isLoading"
                            class="d-sm-flex align-items-center justify-content-center"
                        >
                            <b-spinner small></b-spinner>
                            <span class="ml-2">Guardando...</span>
                        </span>
                        <span v-else>Guardar</span>
                    </b-button>
                </div>
            </div>
        </b-col>
        <b-col
            cols="12"
            lg="12"
            class="d-flex align-items-stretch"
            style="min-height: 70vh"
        >
            <b-card class="mb-4 w-100">
                <div>
                    <b-form class="p-5">
                        <div class="d-flex">
                            <div>
                                <h3 class="mb-2">Datos de la factura</h3>
                                <div class="text-gray mb-5">
                                    Por favor registre los datos de la factura
                                    para continuar el registro
                                </div>
                            </div>
                        </div>
                        <b-row v-hotkey="keymap">
                            <b-col cols="12" sm="6">
                                <b-form-group>
                                    <label for="nitTercero">Empresa</label>
                                    <b-form-input
                                        id="nitTercero"
                                        type="number"
                                        v-model="$v.form.nitTercero.$model"
                                        disabled
                                    >
                                    </b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" sm="6">
                                <b-form-group>
                                    <label for="establecimientoId">Sede</label>
                                    <jautocomplete
                                        id="establecimientoId"
                                        :items="establecimientos"
                                        item-text="description"
                                        item-value="id"
                                        open-on-focus
                                        :disabled="
                                            establecimientos.length === 0 ||
                                            read_only ||
                                            isLoading
                                        "
                                        v-model="
                                            $v.form.establecimientoId.$model
                                        "
                                        :state="
                                            validateState('establecimientoId')
                                        "
                                    />
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="$v.form.establecimientoId.$error"
                                    >
                                        Campo requerido
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-md-4">
                            <b-col cols="12">
                                <b-form-group>
                                    <label for="file">ZIP de la factura</label>
                                    <b-form-file
                                        id="file"
                                        accept="application/zip"
                                        :state="validateState('file')"
                                        v-model="$v.form.file.$model"
                                        size="sm"
                                        :disabled="isLoading"
                                    ></b-form-file>
                                    <b-form-invalid-feedback
                                        v-if="!$v.form.file.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-form>
                </div>
            </b-card>
        </b-col>
        <b-col cols="12">
            <div class="float-right">
                <div class="d-flex flex-row">
                    <b-button
                        variant="secondary"
                        :disabled="isLoading"
                        v-b-popover.hover.top="'Presione esc'"
                        size="md"
                        class="mr-4"
                        @click="back"
                    >
                        Cancelar
                    </b-button>

                    <b-button
                        variant="primary"
                        :disabled="isLoading || read_only"
                        size="md"
                        class="d-flex align-items-center"
                        v-b-popover.hover.top="
                            'Presione ctrl+shift+s o cmd+shift+s'
                        "
                        @click="save"
                    >
                        <feather
                            type="save"
                            size="1rem"
                            class="mr-2 text-blue-active"
                        />
                        <span
                            v-if="isLoading"
                            class="d-sm-flex align-items-center justify-content-center"
                        >
                            <b-spinner small></b-spinner>
                            <span class="ml-2">Guardando...</span>
                        </span>
                        <span v-else>Guardar</span>
                    </b-button>
                </div>
            </div>
            <progressBar :percentage="percentage" />
        </b-col>
    </b-row>
</template>
<script>
import services from '@/boot/axios'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import jautocomplete from '@/components/shared/autocomplete/jautocomplete'
import {
    addListenerCommands,
    removeListenerCommands,
    compareObjects,
    getBase64File,
    onUploadProgress
} from '@/utils/others'
import progressBar from '@/components/shared/progress-bar/progressBar.vue'

const { API } = services

const defaultForm = () =>
    JSON.parse(
        JSON.stringify({
            nitTercero: '',
            establecimientoId: null,
            file: null
        })
    )

export default {
    name: 'SubirFactura',
    mixins: [validationMixin],
    components: { jautocomplete, progressBar },
    data: () => ({
        establecimientos: [],
        formCopy: defaultForm(),
        form: defaultForm(),
        isLoading: false,
        isSaved: true,
        read_only: false,
        percentage: 0
    }),
    watch: {
        form: {
            handler(newValue) {
                this.isSaved = compareObjects(this.formCopy, newValue)
            },
            deep: true
        }
    },
    mounted() {
        addListenerCommands(this)
        this.form.nitTercero = this.getUltimaEmpresa.numero_documento
        this.formCopy.nitTercero = this.getUltimaEmpresa.numero_documento
        this.getEstablecimientosByNit(this.getUltimaEmpresa.numero_documento)
        // console.log(this.getUltimaEmpresa)
    },
    beforeDestroy() {
        removeListenerCommands(this)
    },
    beforeRouteLeave(to, from, next) {
        if (this.isSaved) {
            next()
        } else {
            this.$bvModal
                .msgBoxConfirm(
                    `
        Este formulario contiene información que no ha sido guardada,
        si continua con esta acción no se guardarán los cambios, desea continuar con esta acción?`,
                    {
                        title: 'Advertencia!',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Continuar',
                        cancelTitle: 'Cancelar',
                        footerClass: 'p-2 d-flex justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                        headerBgVariant: 'danger',
                        headerClass: 'modal-header'
                    }
                )
                .then((value) => {
                    if (value) {
                        next()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },
    validations: {
        form: {
            nitTercero: {},
            establecimientoId: {
                required
            },
            file: {
                required
            }
        }
    },
    methods: {
        showLog(data) {
            console.log(data)
        },
        getEstablecimientosByNit(nit) {
            if (nit) {
                API.POST({
                    url: 'procesos-fe/request',
                    data: {
                        empresaId: this.getUltimaEmpresa.id,
                        body: {},
                        requestType: 'GET',
                        endpoint: `/api/establecimiento-comercio/terceros/${nit}`
                    }
                })
                    .then((response) => {
                        // console.log(response)
                        const {
                            data: {
                                dato,
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        if (status === 'ok') {
                            this.establecimientos = dato
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje_exception ||
                                        mensaje ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            }
        },
        async save() {
            const valid = !this.$v.form.$invalid
            if (valid) {
                this.isLoading = true
                const base64File = await getBase64File(this.form.file)

                const body = {
                    file: base64File,
                    establecimientoId: this.form.establecimientoId
                }

                API.POST({
                    url: 'procesos-fe/request',
                    data: {
                        empresaId: this.getUltimaEmpresa.id,
                        body: {
                            ...body
                        },
                        requestType: 'POST',
                        endpoint: '/api/documents-reception/upload'
                    },
                    onUploadProgress: (loadProgress) => {
                        const { progress, percentage } =
                            onUploadProgress(loadProgress)

                        console.log(progress, percentage, this.$bvModal)
                        this.percentage = percentage

                        this.$bvModal.show('progress-bar-modal')
                    }
                })
                    .then((response) => {
                        // console.log(response)
                        this.isLoading = false
                        const {
                            data: {
                                dato,
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        if (status === 'ok') {
                            console.log(dato)
                            this.$bvModal
                                .msgBoxOk(dato.message, {
                                    title: 'Exito!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'success',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'success',
                                    headerClass: 'modal-header'
                                })
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                            this.form = {
                                ...defaultForm(),
                                nitTercero:
                                    this.getUltimaEmpresa.numero_documento
                            }
                            this.$v.form.$reset()
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje_exception ||
                                        mensaje ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        this.isLoading = false
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            } else {
                this.$v.form.$touch()
            }
        },
        back() {
            this.$router.back()
        },
        validateState(key) {
            const { $dirty, $error } = this.$v.form[key]
            return $dirty ? !$error : null
        }
    },
    computed: {
        keymap() {
            if (
                navigator.platform.toLowerCase().includes('mac') ||
                navigator.platform.toLowerCase().includes('macintosh')
            ) {
                return {
                    'command+shift+s': this.read_only ? () => {} : this.save,
                    esc: this.back
                }
            } else if (
                navigator.platform.toLowerCase().includes('win') ||
                navigator.platform.toLowerCase().includes('windows')
            ) {
                return {
                    'ctrl+shift+s': this.read_only ? () => {} : this.save,
                    esc: this.back
                }
            } else {
                return {}
            }
        },
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 21 }
            return json
        },
        ...mapGetters('usuario', ['getUserData', 'getAuditoriaJson']),
        ...mapGetters('empresas', ['getUltimaEmpresa'])
    }
}
</script>

<style></style>
