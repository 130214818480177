var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-sm-flex justify-content-between align-items-center mb-4"},[_c('feather',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Presione esc'),expression:"'Presione esc'",modifiers:{"hover":true,"top":true}}],staticClass:"text-secondary",attrs:{"type":"arrow-left-circle","role":"button","size":"2rem"},on:{"click":_vm.back}}),_c('div',{staticClass:"d-flex flex-row"},[_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Presione esc'),expression:"'Presione esc'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-4",attrs:{"variant":"secondary","disabled":_vm.isLoading,"size":"md"},on:{"click":_vm.back}},[_vm._v(" Cancelar ")]),_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                        'Presione ctrl+shift+s o cmd+shift+s'
                    ),expression:"\n                        'Presione ctrl+shift+s o cmd+shift+s'\n                    ",modifiers:{"hover":true,"top":true}}],staticClass:"d-flex align-items-center",attrs:{"variant":"primary","disabled":_vm.isLoading || _vm.read_only,"size":"md"},on:{"click":_vm.save}},[_c('feather',{staticClass:"mr-2 text-blue-active",attrs:{"type":"save","size":"1rem"}}),(_vm.isLoading)?_c('span',{staticClass:"d-sm-flex align-items-center justify-content-center"},[_c('b-spinner',{attrs:{"small":""}}),_c('span',{staticClass:"ml-2"},[_vm._v("Guardando...")])],1):_c('span',[_vm._v("Guardar")])],1)],1)],1)]),_c('b-col',{staticClass:"d-flex align-items-stretch",staticStyle:{"min-height":"70vh"},attrs:{"cols":"12","lg":"12"}},[_c('b-card',{staticClass:"mb-4 w-100"},[_c('div',[_c('b-form',{staticClass:"p-5"},[_c('div',{staticClass:"d-flex"},[_c('div',[_c('h3',{staticClass:"mb-2"},[_vm._v("Datos de la factura")]),_c('div',{staticClass:"text-gray mb-5"},[_vm._v(" Por favor registre los datos de la factura para continuar el registro ")])])]),_c('b-row',{directives:[{name:"hotkey",rawName:"v-hotkey",value:(_vm.keymap),expression:"keymap"}]},[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"nitTercero"}},[_vm._v("Empresa")]),_c('b-form-input',{attrs:{"id":"nitTercero","type":"number","disabled":""},model:{value:(_vm.$v.form.nitTercero.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.nitTercero, "$model", $$v)},expression:"$v.form.nitTercero.$model"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"establecimientoId"}},[_vm._v("Sede")]),_c('jautocomplete',{attrs:{"id":"establecimientoId","items":_vm.establecimientos,"item-text":"description","item-value":"id","open-on-focus":"","disabled":_vm.establecimientos.length === 0 ||
                                        _vm.read_only ||
                                        _vm.isLoading,"state":_vm.validateState('establecimientoId')},model:{value:(
                                        _vm.$v.form.establecimientoId.$model
                                    ),callback:function ($$v) {_vm.$set(_vm.$v.form.establecimientoId, "$model", $$v)},expression:"\n                                        $v.form.establecimientoId.$model\n                                    "}}),(_vm.$v.form.establecimientoId.$error)?_c('div',{staticClass:"invalid-field mt-1"},[_vm._v(" Campo requerido ")]):_vm._e()],1)],1)],1),_c('b-row',{staticClass:"mt-md-4"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"file"}},[_vm._v("ZIP de la factura")]),_c('b-form-file',{attrs:{"id":"file","accept":"application/zip","state":_vm.validateState('file'),"size":"sm","disabled":_vm.isLoading},model:{value:(_vm.$v.form.file.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.file, "$model", $$v)},expression:"$v.form.file.$model"}}),(!_vm.$v.form.file.required)?_c('b-form-invalid-feedback',[_vm._v(" Campo requerido ")]):_vm._e()],1)],1)],1)],1)],1)])],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"float-right"},[_c('div',{staticClass:"d-flex flex-row"},[_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Presione esc'),expression:"'Presione esc'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-4",attrs:{"variant":"secondary","disabled":_vm.isLoading,"size":"md"},on:{"click":_vm.back}},[_vm._v(" Cancelar ")]),_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                        'Presione ctrl+shift+s o cmd+shift+s'
                    ),expression:"\n                        'Presione ctrl+shift+s o cmd+shift+s'\n                    ",modifiers:{"hover":true,"top":true}}],staticClass:"d-flex align-items-center",attrs:{"variant":"primary","disabled":_vm.isLoading || _vm.read_only,"size":"md"},on:{"click":_vm.save}},[_c('feather',{staticClass:"mr-2 text-blue-active",attrs:{"type":"save","size":"1rem"}}),(_vm.isLoading)?_c('span',{staticClass:"d-sm-flex align-items-center justify-content-center"},[_c('b-spinner',{attrs:{"small":""}}),_c('span',{staticClass:"ml-2"},[_vm._v("Guardando...")])],1):_c('span',[_vm._v("Guardar")])],1)],1)]),_c('progressBar',{attrs:{"percentage":_vm.percentage}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }