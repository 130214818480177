<template>
    <b-modal
        id="progress-bar-modal"
        header-bg-variant="primary"
        hide-footer
        :body-class="`${Theme === 'dark' && 'dark'} dialog2`"
        dialog-class="dialog"
        centered
    >
        <template #modal-header>
            <h5 class="text-white">Cargando archivo(s)</h5>
        </template>
        <b-card class="p-3">
            <b-progress :max="100" animated showProgress height="2rem">
                <b-progress-bar :value="percentage">
                    <span
                        ><strong>{{ percentage }}% / 100%</strong></span
                    >
                </b-progress-bar>
            </b-progress>
        </b-card>
    </b-modal>
</template>
<script>
import { mapState } from 'vuex'

export default {
    name: 'ProgressBarModal',
    data: () => ({}),
    props: { percentage: { type: Number, default: 0 } },
    methods: {
        close() {
            this.$bvModal.hide('progress-bar-modal')
        }
    },
    computed: {
        // ...mapGetters('empresas', ['getUltimaEmpresa']),
        ...mapState(['Theme'])
    }
}
</script>

<style>
.dialog {
    min-width: 40%;
}

.dialog2 {
    min-height: auto;
}
</style>
